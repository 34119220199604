// import * as React from 'react';
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { headerData, headerDataForm } from "../../data/headerCostum";
import { useLocation, useNavigate } from "react-router-dom";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ImageIcon from '@mui/icons-material/Image';
import { styled } from '@mui/material/styles';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SweetAlert2 from "react-sweetalert2";
import { useAuth } from "../../auth/auth_provider";
import { useLocalStorage } from "../../auth/useLocalStorage";
import Compressor from "compressorjs";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="#FFFFFF"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://makassarkota.go.id/">
        Pemerintah Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const LaporanSistem = () => {
  const navigate = useNavigate();

  const [swalProps, setSwalProps] = useState({});
  const [files, setFiles] = useState([]);

  const [longwis, setLongwis] = useLocalStorage("longwis");

  const { pic } = useAuth();

  const [data, setData] = useState({
    status_fisik: '',
    keterangan: '',
    no_serial: ''
  });

  const uploadFile = (filess) => {
    if (files.length + filess.length <= 4) {
      if (filess.length > 1) {
        let file_data = [];
        [...filess].forEach((elem, i) => {
          // file_data.push(elem);
          new Compressor(elem, {
            quality: 0.7,
            maxWidth: 400,
            success(result) {
              file_data.push(result);
            },
            error(err) {
              console.log(err);
            }
          });
        });
        setFiles([...files, ...file_data]);
      } else {
        new Compressor(filess[0], {
          quality: 0.7,
          maxWidth: 400,
          success(result) {
            // form_data.append(`file-${i}`, result, result.name);
            setFiles([...files, result]);
          },
          error(err) {
            console.log(err);
          }
        });
        // setFiles([...files, filess[0]]);
      }
    } else {
      setSwalProps({
        show: true,
        title: "Gagal Pilih File",
        icon: "error",
        text: "File Upload Maksimal 4",
      });
    }
  }

  const packFiles = (filess) => {
    const form_data = new FormData();
    if (filess.length) {
      [...filess].forEach((file, i) => {
        form_data.append(`file-${i}`, file, file.name);
      })
    }
    form_data.append('no_serial', '0');
    form_data.append('keterangan', data.keterangan);
    form_data.append('status_tampilan', data.status_fisik);
    form_data.append('id_user', pic.user.id)
    form_data.append('id_longwis', longwis.id)

    // console.log(form_data.get('file-1'))
    // console.log(longwis);
    return form_data;
  }

  const sendData = (event) => {
    event.preventDefault();
    const file_data = packFiles(files);
    // setsistem(file_data);
    // console.log(sistem);
    fetch(process.env.REACT_APP_API_URL + "api/system", {
      method: "post",
      headers: headerDataForm,
      body: file_data,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res) {
          navigate("/pic/laporan", {
            state: {
              sistem: res,
            }
          });
        } else {
          setSwalProps({
            show: true,
            title: "Gagal",
            icon: "error",
            text: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };



  useEffect(() => {
    // if (sistem) {
    //   setData(sistem)
    // }
  }, []);

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        // backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
          <CssBaseline />
          <SweetAlert2
            {...swalProps}
            didClose={() => {
              setSwalProps({});
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                marginTop: 5,
              }}
            >
              <Typography>
                {"Foto QR Code"}
              </Typography>
            </Box>
            <Box
              component="form"
              onSubmit={sendData}
              sx={{ mt: 1, paddingX: 2 }}
            >
              <FormControl
                sx={{
                  marginTop: 2
                }} fullWidth>
                <InputLabel size="small" id="select-type-user">{'Status Fisik'}</InputLabel>
                <Select
                  labelId="select-type-user"
                  id="type-user"
                  value={data.status_fisik}
                  required
                  placeholder="Status Fisik"
                  label=""
                  size="small"
                  onChange={(e) => setData({ ...data, status_fisik: e.target.value })}
                >
                  <MenuItem value={1}>Tampilan System Online</MenuItem>
                  <MenuItem value={0}>Tampilan System Offline</MenuItem>
                </Select>
              </FormControl>
              {/* <TextField
                margin="normal"
                required
                fullWidth
                id="no_serial"
                placeholder="Serial Number"
                name="no_serial"
                value={data.no_serial}
                onChange={(event) => setData({ ...data, no_serial: event.target.value })}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              /> */}
              <Grid
                container
                direction={'column'}
                alignContent={'center'}
                alignItems={'center'}
                sx={{
                  marginTop: 2,
                  paddingY: 2,
                  paddingX: 1,
                  border: 1,
                  borderColor: '#A7A7A7',
                  borderRadius: 3,
                  backgroundColor: 'rgba(22, 235, 107, 0.1)'
                }}
              >
                <FileUploadIcon color="error" />
                <Typography sx={{
                  marginY: 1
                }}>
                  {'Ambil Foto atau Upload File'}
                </Typography>
                <Button
                  sx={{
                    marginLeft: 1,
                    backgroundColor: '#A6A6A6',
                    ':hover': {
                      backgroundColor: '#6B6B6B',
                    },
                    textTransform: "capitalize",
                    borderRadius: 3,
                  }}
                  size="medium" component="label" variant="contained">
                  {'Pilih'}
                  <VisuallyHiddenInput type="file" multiple name="file" onChange={
                    (ev) => {
                      // uploadFile("file", ev.target.files[0])
                      uploadFile(ev.target.files);
                      // setFiles(ev.target.files)
                    }
                  } />
                </Button>
              </Grid>

              <Typography fontSize={14} marginTop={1}>
                {'Only .jpg, .png Files Type, 500kb max file size.'}
              </Typography>
              {
                [...files].map((f, i) => (
                  <Grid
                    container
                    direction={'row'}
                    // alignContent={'center'}
                    alignItems={'center'}
                    key={i}
                    sx={{
                      marginTop: 2,
                      paddingY: 2,
                      paddingX: 1,
                      border: 1,
                      borderColor: '#A7A7A7',
                      borderRadius: 3,
                      backgroundColor: 'rgba(22, 235, 107, 0.1)'
                    }}
                  >
                    <Grid paddingX={2} paddingTop={1}>
                      <ImageIcon fontSize="large" color="disabled" />
                    </Grid>
                    <Grid item>
                      <Typography fontSize={12}>
                        {f.name}
                      </Typography>
                      <Typography fontSize={12}>
                        {f.size} byte
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              }
              <TextField
                margin="normal"
                fullWidth
                multiline
                rows={3}
                id="keterangan"
                placeholder="Keterangan"
                name="keterangan"
                value={data.keterangan}
                onChange={(event) => setData({ ...data, keterangan: event.target.value })}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgba(65, 178, 87, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  ":hover": {
                    backgroundColor: "rgba(65, 178, 87, 0.7)",
                  },
                }}
                size="medium"
              >
                {'Simpan'}
              </Button>
            </Box>
            <Box sx={{ fontSize: 14, color: "#FFFFFF", textAlign: "center" }}>
              {/* Pemerintah Kota Makassar 2024 */}
              {/* <Box>
                Silahkan cek
                <Button
                  sx={{ color: "#FFF000", textDecoration: "underline" }}
                  onClick={() => navigate("cekqr")}
                >
                  di SINI
                </Button>
              </Box> */}
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default LaporanSistem;
