import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import Longwis1 from "./longwis1";
import Longwis18 from "./longwis18";

const Dashboard = () => {
  const [longwis, setLongwis] = useState(244);

  return (
    <Box>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Pilih Longwis</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={longwis}
            label="Age"
            onChange={(ev) => setLongwis(ev.target.value)}
          >
            <MenuItem value={244}>Longwis 244</MenuItem>
            <MenuItem value={1815}>Longwis 1815</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
          {longwis==244 ? <Longwis1 /> : <Longwis18 />}
      </Box>
    </Box>
  );
};

export default Dashboard;
