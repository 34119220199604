import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

function Copyright(props) {
    return (
        <Typography variant="body2" color="darkslategray" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();


const HalPilihLayanan = () => {
    const navigate = useNavigate();

    return (
        <Box
            width={"100vw"}
            height={"100vh"}
            sx={{
                // backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "right bottom",
                position: "absolute",
                alignContent: "center",
                alignItems: "center",
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "start",
                            height: "100vh",
                        }}
                    >
                        <Box
                            sx={{
                                marginTop: 5,
                            }}
                        ></Box>
                        <Box sx={{ marginTop: 2 }}>
                            <Typography fontSize={17} align="center" fontWeight={700}>
                                {"Pilih Layanan"}
                            </Typography>
                        </Box>
                        <Box
                            component="form"
                            sx={{ mt: 1, paddingX: 2 }}
                        >

                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => navigate("/pic/longwis")}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    background: `linear-gradient(90deg, rgba(29, 195, 159, 1), rgba(143, 249, 171, 1));`,
                                    textTransform: "none",
                                    fontWeight: 500,
                                    color: "#FFFFFF",
                                    ":hover": {
                                        backgroundColor: "rgba(65, 178, 87, 0.7)",
                                    },
                                }}
                                size="medium"
                            >
                                {'Cek Longwis 244'}
                            </Button>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => navigate("/pic/longwis-lokasi")}
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    background: `linear-gradient(90deg, rgba(29, 195, 159, 1), rgba(143, 249, 171, 1));`,
                                    textTransform: "none",
                                    fontWeight: 500,
                                    color: "#FFFFFF",
                                    ":hover": {
                                        backgroundColor: "rgba(65, 178, 87, 0.7)",
                                    },
                                }}
                                size="medium"
                            >
                                {'Laporan Longwis 1815'}
                            </Button>
                        </Box>
                        <Box paddingTop={2}>
                            <Copyright />
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </Box>
    );
}

export default HalPilihLayanan;