import React from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";

const Layout_pic = () => {
    return (
        <Grid>
            <Outlet />
        </Grid>
    );
}

export default Layout_pic;