// import * as React from 'react';
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { headerData } from "../../data/headerCostum";
import MainFlyer from "../../assets/image/main_flyer.png";
import DokumenRPJPD from "../../assets/file/Rancangan_RPJPD_2025_2045.pdf";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import SweetAlert2 from "react-sweetalert2";
import DownloadRounded from "@mui/icons-material/DownloadRounded";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="#FFFFFF"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://makassarkota.go.id/">
        Pemerintah Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Konten = () => {
  const navigate = useNavigate();
  const [swalProps, setSwalProps] = useState({});
  const [data, setData] = useState(null);

  const getParam = useLocation();
  const user = getParam.state.user;

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (event) => {
    event.preventDefault();
    setOpenDialog(true);
    const data = new FormData(event.currentTarget);
    setData(data);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `DokumenRPJPD.pdf`;
    link.href = DokumenRPJPD;
    link.click();
  };

  const sendData = () => {
    fetch(process.env.REACT_APP_API_URL + "api/konten", {
      method: "post",
      headers: headerData,
      body: JSON.stringify({
        visi: data.get("visi"),
        misi: data.get("misi"),
        arah_kebijakan: data.get("arah_kebijakan"),
        sasaran_pokok: data.get("sasaran_pokok"),
        user_id: user.id,
        no_hp: user.no_hp
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.visi) {
          setSwalProps({
            show: true,
            title: "Sukses",
            icon: "success",
            text: "Data telah ditambahkan",
          });
          navigate("/sukses", {
            state: {
              user: res,
            },
          });
        } else {
          setSwalProps({
            show: true,
            title: "Gagal",
            icon: "error",
            text: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenDialog(false);
  };

  // const getDataSKPD = useCallback(async () => {
  //   await fetch(process.env.REACT_APP_API_URL + "api/skpd", {
  //     method: "get",
  //     headers: headerData,
  //   })
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((res) => {
  //       setSkpd(res.sort((a, b) => a.nama - b.nama));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [setSkpd]);

  // useEffect(() => {
  //   if (!skpd) {
  //     getDataSKPD();
  //   }
    
  // }, [skpd, getDataSKPD]);

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
          <CssBaseline />
          <SweetAlert2
            {...swalProps}
            didClose={() => {
              setSwalProps({});
            }}
          />
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              align="center"
              sx={{ justifyItems: "center" }}
              id="alert-dialog-title"
            >
              Konfirmasi
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Apakah anda yakin bahwa data yang dimasukkan telah sesuai ?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                sx={{
                  background: "#B40000",
                }}
              >
                Kembali
              </Button>
              <Button
                variant="contained"
                onClick={sendData}
                autoFocus
                sx={{
                  background: "#4985F8",
                }}
              >
                Yakin
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                top: 0,
              }}
            >
              <img src={MainFlyer} alt="Main Flyer" width={"100%"} />
            </Box>
            {/* <Box sx={{ marginTop: 2 }}>
              <Typography fontSize={27} fontWeight={700} color={"#FFFFFF"}>
                {"Registrasi Rakorsus 2024"}
              </Typography>
            </Box> */}
            <Box
              component="form"
              onSubmit={handleOpenDialog}
              sx={{ mt: 1, paddingX: 2 }}
            >
              {/* <Divider
                orientation="horizontal"
                variant="fullWidth"
                sx={{ backgroundColor: "#FFFFFF" }}
              /> */}
              <TextField
                margin="dense"
                required
                fullWidth
                id="visi"
                placeholder="Visi*"
                type="textarea"
                name="visi"
                minRows={3}
                maxRows={5}
                multiline
                // autoComplete="username"
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <TextField
                margin="dense"
                required
                fullWidth
                name="misi"
                placeholder="Misi*"
                type="textarea"
                id="misi"
                size="small"
                minRows={3}
                maxRows={5}
                multiline
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <TextField
                margin="dense"
                required
                fullWidth
                name="arah_kebijakan"
                placeholder="Arah Kebijakan*"
                type="text"
                id="arah_kebijakan"
                // autoComplete="current-password"
                size="small"
                minRows={3}
                maxRows={5}
                multiline
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <TextField
                margin="dense"
                required
                fullWidth
                name="sasaran_pokok"
                placeholder="Sasaran Pokok*"
                type="text"
                id="sasaran_pokok"
                // autoComplete="current-password"
                size="small"
                minRows={3}
                maxRows={5}
                multiline
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={() => onDownload()}
                sx={{
                  marginY: 1,
                  backgroundColor: "rgba(231, 231, 231, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#000000",
                  ":hover": {
                    backgroundColor: "rgba(231, 231, 231, 0.7)",
                  },
                }}
                size="medium"
                startIcon={<DownloadRounded />}
              >
                {'DOWNLOAD RPJPD 2025 - 2045'}
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgba(65, 178, 87, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  ":hover": {
                    backgroundColor: "rgba(65, 178, 87, 0.7)",
                  },
                }}
                size="medium"
              >
                {'Submit'}
              </Button>
            </Box>
            <Box sx={{ fontSize: 14, color: "#FFFFFF", textAlign: "center" }}>
              {/* Pemerintah Kota Makassar 2024 */}
              {/* <Box>
                Silahkan cek
                <Button
                  sx={{ color: "#FFF000", textDecoration: "underline" }}
                  onClick={() => navigate("cekqr")}
                >
                  di SINI
                </Button>
              </Box> */}
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default Konten;
