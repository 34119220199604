// import * as React from 'react';
import React, { useCallback, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from '@mui/material/styles';
import {
  Grid,
} from "@mui/material";
import PrintPDF from "./download_laporan";
import ReactPDF, { PDFDownloadLink, Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import jsPDF from "jspdf";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import PrintPDFLokasi from "./download_laporan_lokasi";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="#FFFFFF"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://makassarkota.go.id/">
        Pemerintah Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});


// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const data = {
  nama_pic: "Muhammad Rezky",
  kontak_pic: "0895805040352",
  nama_longwis: "Buger",
  kecamatan: "Bontoala",
  kelurahan: "Prang Layang",
  alamat: "Jln. Hj. Manila Dg. Pati No. 32",
  tanggal: "2024-05-29",
  status: "Sedang Dikerjakan",
  status_fisik_cctv: "1",
  status_fisik_nvr: "1",
  status_tampilan_system: "1",
  status_tampilan_layar: "1"
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    // backgroundColor: '#E4E4E4',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
})

const DaftarLaporanLokasi = () => {
  const reportTemplate = useRef(null);

  useEffect(() => {
    // if (sistem) {
    //   setData(sistem)
    // }
  }, []);

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        // backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                marginTop: 5,
              }}
            >
              <Typography variant="h6" fontWeight={700} textTransform={"uppercase"} >
                {"Download Laporan"}
              </Typography>
            </Box>
            <Box
              component="form"
              // onSubmit={sendData}
              sx={{ mt: 1, paddingX: 2 }}
            >
              <Grid container justifyContent={"center"} direction={"column"}>
                <Grid item container>
                  <Box component={"div"} sx={{ overflow: "scroll", width: "500px", height: "600px" }}>
                    <Grid container ref={reportTemplate}>
                      <PrintPDFLokasi />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Button
                fullWidth
                variant="contained"
                onClick={() => window.open("/#/pic/download/lokasi", "_blank")}
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgba(65, 178, 87, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  ":hover": {
                    backgroundColor: "rgba(65, 178, 87, 0.7)",
                  },
                }}
                size="medium"
              >
                {"Download Laporan"}
              </Button>
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default DaftarLaporanLokasi;
