import { Box, Card, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { headerData } from "../../data/headerCostum";
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { ResponsiveChartContainer } from "@mui/x-charts";

const Longwis1 = () => {
  const [dataLongwis, setDataLongwis] = useState(null);
  const [dataKecamatan, setDataKecamatan] = useState(null);
  const [dataKelurahan, setDataKelurahan] = useState(null);

  const getDataListLongwis = useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "api/longwis", {
      method: 'get',
      headers: headerData
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setDataLongwis(data);
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const getDataKecamatan = useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "api/longwis/kecamatan", {
      method: 'get',
      headers: headerData
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setDataKecamatan(data);
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  const getDataKelurahan = useCallback(() => {
    fetch(process.env.REACT_APP_API_URL + "api/longwis/kelurahan", {
      method: 'get',
      headers: headerData
    })
      .then(res => {
        return res.json()
      })
      .then(data => {
        setDataKelurahan(data)
      })
      .catch(err => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (!dataLongwis) {
      getDataListLongwis();
    }
    if (!dataKecamatan) {
      getDataKecamatan();
    }

    if(!dataKelurahan){
      getDataKelurahan();
    }

  }, [dataLongwis, getDataListLongwis, dataKecamatan, getDataKecamatan, dataKelurahan, getDataKelurahan]);

  const valueFormatter = (value) => `${value} laporan`;
  const chartSetting = {
    xAxis: [
      {
        label: 'Jumlah',
      },
    ],
    width: 800,
    height: 1000,
    sx: {
      margin: 6
      // [`.${axisClasses.left} .${axisClasses.label}`]: {
      //   transform: 'translate(-20px, 0)',
      // },
    },
  };

  const chartSettingKec = {
    xAxis: [
      {
        label: 'Jumlah',
      },
    ],
    width: 700,
    height: 600,
    sx: {
      margin: 7
      // [`.${axisClasses.left} .${axisClasses.label}`]: {
      //   transform: 'translate(-20px, 0)',
      // },
    },
  };

  return (
    <Box>
      <Grid container direction={"row"} mt={2} spacing={2}>
        <Grid item>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={300}>
                      <Typography textTransform={'uppercase'} fontWeight={700}>
                        {'Kecamatan'}
                      </Typography>
                    </TableCell>
                    <TableCell width={300}>
                      <Typography textTransform={'uppercase'} fontWeight={700}>
                        {'Jumlah'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    dataKecamatan
                      ? dataKecamatan.map((item) => {
                        return (
                          <TableRow>
                            <TableCell>
                              {item.kecamatan}
                            </TableCell>
                            <TableCell>
                              {item.jumlah}
                            </TableCell>
                          </TableRow>
                        )
                      })
                      : <></>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item>
          {
            dataKecamatan
              ? <BarChart
                dataset={dataKecamatan}
                yAxis={[{ scaleType: 'band', dataKey: 'kecamatan', categoryGapRatio: 0.3 }]}
                series={[{
                  dataKey: 'jumlah', label: 'Laporan Per Kecamatan', valueFormatter
                }]}
                layout="horizontal"
                barLabel={'value'}
                {...chartSettingKec}
              />
              : <></>
          }
        </Grid>
      </Grid>

      <Grid container direction={"row"} mt={2} spacing={2}>
        <Grid item>
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width={250}>
                      <Typography textTransform={'uppercase'} fontWeight={700}>
                        {'Kecamatan'}
                      </Typography>
                    </TableCell>
                    <TableCell width={250}>
                      <Typography textTransform={'uppercase'} fontWeight={700}>
                        {'Kelurahan'}
                      </Typography>
                    </TableCell>
                    <TableCell width={100}>
                      <Typography textTransform={'uppercase'} fontWeight={700}>
                        {'Jumlah'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    dataKelurahan
                      ? dataKelurahan.map((item) => {
                        return (
                          <TableRow>
                            <TableCell>
                              {item.kecamatan}
                            </TableCell>
                            <TableCell>
                              {item.kelurahan}
                            </TableCell>
                            <TableCell>
                              {item.jumlah}
                            </TableCell>
                          </TableRow>
                        )
                      })
                      : <></>
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
        <Grid item>
          {
            dataKelurahan
              ? <BarChart
                dataset={dataKelurahan}
                yAxis={[{ scaleType: 'band', dataKey: 'kelurahan', categoryGapRatio: 0.3 }]}
                series={[{
                  dataKey: 'jumlah', label: 'Laporan Per Kelurahan', valueFormatter
                }]}
                layout="horizontal"
                barLabel={'value'}
                {...chartSetting}
              />
              : <></>
          }
        </Grid>
      </Grid>
    </Box>
  );
};

export default Longwis1;
