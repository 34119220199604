// import * as React from 'react';
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { headerData } from "../../data/headerCostum";
import MainFlyer from "../../assets/image/main_flyer.png";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import SweetAlert2 from "react-sweetalert2";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="#FFFFFF"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://makassarkota.go.id/">
        Pemerintah Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const Pendaftaran = () => {
  const navigate = useNavigate();
  const [skpd, setSkpd] = useState(null);
  const [opd, setOpd] = useState(null);
  const [swalProps, setSwalProps] = useState({});
  const [data, setData] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (event) => {
    event.preventDefault();
    setOpenDialog(true);
    const data = new FormData(event.currentTarget);
    setData(data);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const sendData = () => {
    fetch(process.env.REACT_APP_API_URL + "api/pendaftar", {
      method: "post",
      headers: headerData,
      body: JSON.stringify({
        nama: data.get("nama").toString().toUpperCase(),
        no_hp: data.get("no_hp"),
        opd: data.get("opd"),
        jabatan: data.get("jabatan")
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.nama) {
          setSwalProps({
            show: true,
            title: "Sukses",
            icon: "success",
            text: "Data telah ditambahkan",
          });
          navigate("/konten", {
            state: {
              user: res,
            },
          });
        } else {
          setSwalProps({
            show: true,
            title: "Gagal",
            icon: "error",
            text: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenDialog(false);
  };

  

  useEffect(() => {
    
    
  }, []);

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
          <CssBaseline />
          <SweetAlert2
            {...swalProps}
            didClose={() => {
              setSwalProps({});
            }}
          />
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              align="center"
              sx={{ justifyItems: "center" }}
              id="alert-dialog-title"
            >
              Konfirmasi
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Apakah anda yakin bahwa data yang dimasukkan telah sesuai ?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                sx={{
                  background: "#B40000",
                }}
              >
                Kembali
              </Button>
              <Button
                variant="contained"
                onClick={sendData}
                autoFocus
                sx={{
                  background: "#4985F8",
                }}
              >
                Yakin
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                top: 0,
              }}
            >
              <img src={MainFlyer} alt="Main Flyer" width={"100%"} />
            </Box>
            <Box sx={{ marginTop: 2 }}>
              <Typography fontSize={17} align="center" fontWeight={700} color={"#FFFFFF"}>
                {"FORM VISI MISI, ARAH KEBIJAKAN"}
              </Typography>
              <Typography fontSize={17} align="center" fontWeight={700} color={"#FFFFFF"}>
                {"& SASARAN POKOK"}
              </Typography>
              <Typography fontSize={17} align="center" fontWeight={700} color={"#FFFFFF"}>
                {"RPJPD 2025-2045"}
              </Typography>
            </Box>
            <Box
              component="form"
              onSubmit={handleOpenDialog}
              sx={{ mt: 1, paddingX: 2 }}
            >
              {/* <Divider
                orientation="horizontal"
                variant="fullWidth"
                sx={{ backgroundColor: "#FFFFFF" }}
              /> */}
              <TextField
                margin="normal"
                required
                fullWidth
                id="nama"
                placeholder="Nama"
                name="nama"
                // autoComplete="username"
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="no_hp"
                placeholder="No Handphone"
                type="number"
                id="no_hp"
                // autoComplete="current-password"
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="opd"
                placeholder="Instansi/Unit Kerja/Lembaga"
                type="text"
                id="opd"
                // autoComplete="current-password"
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              {/* <Autocomplete
                disablePortal
                id="opd"
                options={
                  skpd ? skpd : [{ nama: "loading data mohon tunggu.." }]
                }
                value={opd}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  setOpd(newValue);
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Organisasi Perangkat Daerah"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              /> */}
              <TextField
                margin="normal"
                required
                fullWidth
                name="jabatan"
                placeholder="Jabatan"
                type="text"
                id="jabatan"
                // autoComplete="current-password"
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgba(65, 178, 87, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  ":hover": {
                    backgroundColor: "rgba(65, 178, 87, 0.7)",
                  },
                }}
                size="medium"
              >
                {'Selanjutnya'}
              </Button>
            </Box>
            <Box sx={{ fontSize: 14, color: "#FFFFFF", textAlign: "center" }}>
              {/* Pemerintah Kota Makassar 2024 */}
              {/* <Box>
                Silahkan cek
                <Button
                  sx={{ color: "#FFF000", textDecoration: "underline" }}
                  onClick={() => navigate("cekqr")}
                >
                  di SINI
                </Button>
              </Box> */}
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default Pendaftaran;
