// import * as React from 'react';
import React, { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { headerData } from "../../data/headerCostum";
import MainFlyer from "../../assets/image/main_flyer.png";
import { useNavigate } from "react-router-dom";
import { useGeolocated } from "react-geolocated";
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import SweetAlert2 from "react-sweetalert2";
import { useLocalStorage } from "../../auth/useLocalStorage";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="#FFFFFF"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://makassarkota.go.id/">
        Pemerintah Kota Makassar
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

const LaporanLongwis = () => {
  const navigate = useNavigate();
  const [longwis, setLongwis] = useLocalStorage("longwis", null);
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  const [swalProps, setSwalProps] = useState({});
  const [data, setData] = useState({
    kecamatan: null,
    kelurahan: null,
    longwis: null,
    alamat: null,
    nama_pic: '',
    kontak_pic: '',
    tanggal: null,
    status: ''
  });

  const [kecamatan, setKecamatan] = useState([{
    id: 1,
    nama: 'Mohon Tunggu . . .'
  }])

  const [kelurahan, setKelurahan] = useState([{
    id: 1,
    nama: 'Mohon Tunggu . . .'
  }])

  const [data_longwis, setDataLongwis] = useState([{
    id: 1,
    nama: 'Mohon Tunggu . . .'
  }])

  const [data_alamat, setDataAlamat] = useState([{
    id: 1,
    nama: 'Mohon Tunggu . . .'
  }])

  const [list_longwis, setListLongwis] = useState(null)

  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialog = (event) => {
    event.preventDefault();
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getLongwisData = () => {
    fetch(process.env.REACT_APP_API_URL + "api/listlongwis", {
      method: "get",
      headers: headerData
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res) {
          setListLongwis(res);
          getKecData(res)
        } else {
          setSwalProps({
            show: true,
            title: "Gagal",
            icon: "error",
            text: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const getKecData = useCallback((data) => {
    let kecamatan = [];
    let dataKecamatan = [];
    data.forEach((items) => {
      let indx = 0
      if (kecamatan.includes(items.kecamatan)) {
        return
      } else {
        kecamatan.push(items.kecamatan)
        dataKecamatan.push({ nama: items.kecamatan, id: indx });
        indx += 1;
      }
    })
    setKecamatan(dataKecamatan)
  }, [setKecamatan])

  const kecHandle = useCallback((e) => {
    if (e) {
      const filteredData = [...list_longwis].filter(item => item.kecamatan === e.nama);
      let kelurahan = [];
      let dataKelurahan = [];
      filteredData.forEach((items) => {
        let indx = 0
        if (kelurahan.includes(items.kelurahan)) {
          return
        } else {
          kelurahan.push(items.kelurahan)
          dataKelurahan.push({ nama: items.kelurahan, id: indx });
          indx += 1;
        }
      })
      setKelurahan(dataKelurahan);
    }
  }, [list_longwis, setKelurahan]);

  const kelHandle = useCallback((e) => {
    if (e) {
      const filteredData = [...list_longwis].filter(item => item.kelurahan === e.nama);
      let longwis = [];
      let dataLongwis = [];
      filteredData.forEach((items) => {
        let indx = 0
        if (longwis.includes(items.nama_lorong)) {
          return
        } else {
          longwis.push(items.kelurahan)
          dataLongwis.push({ nama: items.nama_lorong, id: indx });
          indx += 1;
        }
      })
      setDataLongwis(dataLongwis);
    }
  }, [list_longwis, setDataLongwis]);

  const longwisHandle = useCallback((e) => {
    if (e) {
      const filteredData = [...list_longwis].filter(item => item.nama_lorong === e.nama);
      let alamat = [];
      let dataAlamat = [];
      filteredData.forEach((items) => {
        let indx = 0
        if (alamat.includes(items.alamat_lorong)) {
          return
        } else {
          alamat.push(items.nama_lorong)
          dataAlamat.push({ nama: items.alamat_lorong, id: indx });
          indx += 1;
        }
      })
      setDataAlamat(dataAlamat);
    }
  }, [list_longwis, setDataAlamat]);

  const sendData = () => {
    fetch(process.env.REACT_APP_API_URL + "api/longwis", {
      method: "post",
      headers: headerData,
      body: JSON.stringify({
        kecamatan: data.kecamatan.nama,
        kelurahan: data.kelurahan.nama,
        nama_longwis: data.longwis.nama,
        alamat: data.alamat.nama,
        nama_pic: data.nama_pic,
        kontak_pic: data.kontak_pic,
        tanggal: null,
        status: data.status,
        lokasi: null
      }),
    })
      .then((res) => {
        console.log(res)
        return res.json();
      })
      .then((res) => {
        if (res.nama_longwis) {
          setSwalProps({
            show: true,
            title: "Sukses",
            icon: "success",
            text: "Data telah ditambahkan",
          });
          setLongwis(res);
          navigate("/pic/cctv", {
            state: {
              data_longwis: res,
            },
          });
        } else {
          setSwalProps({
            show: true,
            title: "Gagal",
            icon: "error",
            text: res.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setOpenDialog(false);
  };



  useEffect(() => {
    if (!list_longwis) {
      getLongwisData();
    }
  }, [list_longwis, getLongwisData]);

  return (
    <Box
      width={"100vw"}
      height={"100vh"}
      sx={{
        // backgroundImage: `linear-gradient(180deg, rgba(146, 28, 32, 1), rgba(0, 0, 0, 1));`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right bottom",
        position: "absolute",
        alignContent: "center",
        alignItems: "center",
      }}
    >
      <ThemeProvider theme={defaultTheme}>
        <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
          <CssBaseline />
          <SweetAlert2
            {...swalProps}
            didClose={() => {
              setSwalProps({});
            }}
          />
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              align="center"
              sx={{ justifyItems: "center" }}
              id="alert-dialog-title"
            >
              Konfirmasi
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Apakah anda yakin bahwa data yang dimasukkan telah sesuai ?
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                justifyContent: "space-between",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCloseDialog}
                sx={{
                  background: "#B40000",
                }}
              >
                Kembali
              </Button>
              <Button
                variant="contained"
                onClick={sendData}
                autoFocus
                sx={{
                  background: "#4985F8",
                }}
              >
                Yakin
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "start",
              height: "100vh",
            }}
          >
            <Box
              sx={{
                marginTop: 5,
              }}
            >
              <Typography textTransform={"uppercase"} fontWeight={700}>
                {"Daftar Longwis"}
              </Typography>
              {/* <img src={MainFlyer} alt="Main Flyer" width={"100%"} /> */}
            </Box>
            <Box
              component="form"
              onSubmit={handleOpenDialog}
              sx={{ mt: 1, paddingX: 2 }}
            >
              <Autocomplete
                disablePortal
                id="kecamatan"
                options={
                  kecamatan ? kecamatan : [{ nama: "loading data mohon tunggu.." }]
                }
                value={data.kecamatan}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  kecHandle(newValue);
                  setData({ ...data, kecamatan: newValue });
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Kecamatan"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="kelurahan"
                options={
                  kelurahan ? kelurahan : [{ id: 1, nama: "loading data mohon tunggu.." }]
                }
                value={data.kelurahan}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  // setOpd(newValue);
                  kelHandle(newValue);
                  setData({ ...data, kelurahan: newValue });
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Kelurahan"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="longwis"
                options={
                  data_longwis ? data_longwis : [{ id: 1, nama: "loading data mohon tunggu.." }]
                }
                value={data.data_longwis}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  longwisHandle(newValue)
                  setData({ ...data, longwis: newValue });
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Nama Lorong Wisata"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              />
              <Autocomplete
                disablePortal
                id="alamat_longwis"
                options={
                  data_alamat ? data_alamat : [{ id: 1, nama: "loading data mohon tunggu.." }]
                }
                value={data.alamat}
                getOptionLabel={(option) => option.nama}
                onChange={(event, newValue) => {
                  setData({ ...data, alamat: newValue });
                }}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    margin="dense"
                    {...params}
                    fullWidth
                    size="small"
                    placeholder="Alamat Lorong Wisata"
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: 2,
                    }}
                  />
                )}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="nama_pic"
                placeholder="Nama PIC"
                name="nama_pic"
                value={data.nama_pic}
                onChange={(event) => setData({ ...data, nama_pic: event.target.value })}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                type="number"
                id="kontak_pic"
                placeholder="Kontak PIC"
                name="kontak_pic"
                value={data.kontak_pic}
                onChange={(event) => setData({ ...data, kontak_pic: event.target.value })}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              />
              {/* <TextField
                margin="normal"
                required
                fullWidth
                type="date"
                id="tanggal"
                placeholder="Pilih Tanggal"
                name="tanggal"
                value={data.tanggal}
                onChange={(event) => setData({ ...data, tanggal: event.target.value })}
                autoFocus
                size="small"
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: 2,
                }}
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "rgba(65, 178, 87, 1)",
                  textTransform: "none",
                  fontWeight: 500,
                  color: "#FFFFFF",
                  ":hover": {
                    backgroundColor: "rgba(65, 178, 87, 0.7)",
                  },
                }}
                size="medium"
              >
                {'Selanjutnya'}
              </Button>
            </Box>
            <Box sx={{ fontSize: 14, color: "#FFFFFF", textAlign: "center" }}>
              {/* Pemerintah Kota Makassar 2024 */}
              {/* <Box>
                Silahkan cek
                <Button
                  sx={{ color: "#FFF000", textDecoration: "underline" }}
                  onClick={() => navigate("cekqr")}
                >
                  di SINI
                </Button>
              </Box> */}
            </Box>
            <Box>
              <Copyright />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </Box>
  );
};

export default LaporanLongwis;
