import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useLocalStorage("user", null);
    const [pic, setPIC] = useLocalStorage("pic", null);
    const navigate = useNavigate();

    // call this function when you want to authenticate the user
    const login = async (data) => {
        await setUser(data);
        navigate("/admin");
    };
    // call this function to sign out logged in user
    const logout = () => {
        setUser(null);
        navigate("/login", { replace: true });
    };

    const loginPIC = async (data) => {
        await setPIC(data);
        navigate("/pic");
    };

    const logoutPIC = () => {
        setPIC(null);
        navigate("/", { replace: true });
    };

    

    const value = useMemo(
        () => ({
            user,
            pic,
            login,
            logout,
            loginPIC,
            logoutPIC
        }),
        [user]
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};