import React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../auth/auth_provider';
import { headerData } from '../data/headerCostum';
import Logo from "../assets/image/logo.png";

function Copyright(props) {
    return (
        <Typography variant="body2" color="darkslategray" align="center" {...props}>
            {'Copyright © '}
            <Link color="inherit" href="https://diskominfo.makassarkota.go.id/">
                Diskominfo Kota Makassar
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();


const HalLoginPIC = () => {
    const { loginPIC, pic } = useAuth();

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        fetch(process.env.REACT_APP_API_URL + "api/pic/login", {
            method: 'post',
            headers: headerData,
            body: JSON.stringify({
                username: data.get('username'),
                password: data.get('password')
            })
        })
            .then(res => {
                return res.json()
            })
            .then((res) => {
                if (res.token) {
                    loginPIC(res);
                    console.log(res);
                }
            })
            .catch(err => {
                console.log(err)
            })
    };
    return (
        <Box
            width={"100vw"}
            height={"100vh"}
            sx={{
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "right bottom",
                position: "absolute",
                alignContent: "center",
                alignItems: "center",
            }}
        >
            <ThemeProvider theme={defaultTheme}>
                <Container sx={{ paddingX: 0 }} component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "start",
                            height: "100vh",
                        }}
                    >
                        <Box marginTop={10}>
                            <img src={Logo} alt="Main Flyer" width={"100%"} />
                        </Box>
                        <Box sx={{ marginTop: 2 }}>
                            <Typography fontSize={17} align="center" fontWeight={700}>
                                {"CCTV LONGWIS"}
                            </Typography>
                        </Box>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ mt: 1, paddingX: 2 }}
                        >
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                placeholder="Username"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                size="small"
                                sx={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 2,
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                placeholder="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                size="small"
                                sx={{
                                    backgroundColor: "#FFFFFF",
                                    borderRadius: 2,
                                }}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    background: `linear-gradient(90deg, rgba(29, 195, 159, 1), rgba(143, 249, 171, 1));`,
                                    textTransform: "none",
                                    fontWeight: 500,
                                    color: "#FFFFFF",
                                    ":hover": {
                                        backgroundColor: "rgba(65, 178, 87, 0.7)",
                                    },
                                }}
                                size="medium"
                            >
                                {'Masuk'}
                            </Button>
                        </Box>
                        <Box>
                            <Copyright />
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </Box>
    );
}

export default HalLoginPIC;